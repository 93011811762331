<template>
  <div class="w-100 transition">
    <GenericReport
      ref="genericReport"
      title="Relatório Esterilização por equipamento"
      :fields="fields"
      :route="route"
      :rowClicked="rowClicked"
      :topFilters="topFilters"
      rowCursorPointer
      tipoRelatorio="relatorioEquipamentoEsterilizacao"
    />
    <Modal ref="materiaisCicloModal" size="lg">
      <div>
        <div class="header">
          <label class="titleMateriaisModal">Relatórios</label>
        </div>
        <b-table
          responsive
          striped
          hover
          :items="itemsModal"
          :fields="fieldsModal"
          @row-clicked="goToMaterial"
          tbody-tr-class="pointer"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment';

import GenericReport from '@/components/Table/GenericReport';
import Modal from '@/components/Utils/Modal';

export default {
  name: 'EsterilizacaoEquipamento',
  components: {
    GenericReport,
    Modal,
  },
  data() {
    return {
      fields: [
        {
          key: 'equipamentoNome',
          label: 'Equipamento',
        },
        {
          label: 'Lote',
          key: 'lote',
        },
        {
          label: 'Teste',
          key: 'teste',
        },
        {
          label: 'Data/Hora',
          key: 'data',
          formatter: (value) => moment(value).format('DD/MM/YY HH:mm'),
        },
        {
          label: 'Usuário',
          key: 'usuario.nome',
        },
        {
          label: 'Pacotes',
          key: 'pacotes',
        },
      ],
      itemsModal: [],
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade',
          defaultValue: this.$route.params.filters.id_unidade,
          firstOption: {
            value: null,
            label: 'Unidade',
          },
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: this.$route.params.filters.initDate,
          defaultEndDate: this.$route.params.filters.endDate,
        },
        id_tipo_esterilizacao: {
          type: 'genericSelect',
          route: 'tipoEsterilizacao',
          defaultValue: this.$route.params.filters.id_tipo_esterilizacao,
          firstOption: {
            value: null,
            label: 'Processamento',
          },
        },
        id_equipamento: {
          type: 'genericSelect',
          route: 'equipamento',
          defaultValue: this.$route.params.item.equipamento.id_equipamento
            ? this.$route.params.item.equipamento.id_equipamento : -1,
          firstOption: {
            value: null,
            label: 'Equipamento',
          },
        },
        ciclo_urgencia: {
          type: 'select',
          defaultValue: this.$route.params.filters.ciclo_urgencia,
          firstOption: {
            value: null,
            label: 'Ciclo de Urgência',
          },
          options: [
            {
              value: 'true',
              label: 'Sim',
              text: 'Sim',
            },
            {
              value: 'false',
              label: 'Não',
              text: 'Não',
            },
          ],
        },
      },
      fieldsModal: [
        {
          label: 'Tipo de material',
          key: 'tipoMaterial',
        },
        {
          label: 'ID',
          key: 'shownId',
        },
        {
          label: 'Descrição',
          key: 'descricao',
        },
        {
          label: 'Validade',
          key: 'validade',
          formatter(val) {
            return moment(val, 'DD-MM-YYYY').format('DD/MM/YYYY');
          },
        },
      ],
      route: 'equipamento/form/getEquipamento',
      item: {},
    };
  },
  methods: {
    rowClicked(item) {
      this.itemsModal = item.materiais.map((elem) => ({
        tipoMaterial: elem.tipoMaterial.nome,
        shownId: !item.isMaterialGenerico ? elem.shownId : '-',
        id_material: elem.id_material,
        descricao: elem.descricao,
        validade: elem.validade,
        isMaterialGenerico: item.isMaterialGenerico,
      }));
      this.$refs.materiaisCicloModal.show();
    },
    goToMaterial(item) {
      if (item.isMaterialGenerico) this.$router.push(`/materialSemQRCode/historico/${item.id_material}`);
      else this.$router.push(`/material/rastrear/${item.id_material}`);
    },
  },
};
</script>
